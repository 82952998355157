// components/CookieConsentPopup.tsx
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // Import js-cookie library

const COOKIE_NAME = 'cookieConsent';


const CookieConsentPopup: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get(COOKIE_NAME);
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set(COOKIE_NAME, 'accepted', { expires: 365 }); // Cookie expires in 365 days
    setIsVisible(false);
  };

  const handleDecline = () => {
    Cookies.set(COOKIE_NAME, 'declined', { expires: 365 }); // Cookie expires in 365 days
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 md:bottom-4 w-full md:w-auto left-1/2 transform -translate-x-1/2 z-50 bg-white text-black px-4 py-2 shadow-lg flex flex-col gap-4 md:flex-row md:gap-6 rounded-lg items-center space-y-2 md:space-y-0">
  <div className="text-center text-[13px] md:text-[14px] md:text-base">
    This website uses cookies to enhance your experience.
    Please accept or decline the use of cookies.
  </div>
  <div className="flex  md:flex-row  space-x-2">
    <button
      onClick={handleDecline}
      className="border border-black px-2 py-1 md:px-4 md:py-2 rounded text-sm md:text-base"
    >
      Decline
    </button>

    <button
      onClick={handleAccept}
      className="bg-[#0070f0] text-white px-2 py-1 md:px-4 md:py-2 rounded text-sm md:text-base"
    >
      Accept
    </button>
  </div>
</div>

  );
};

export default CookieConsentPopup;
