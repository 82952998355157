// 'use client';  // Mark this as a Client Component



// import React, { createContext, useContext, useState, useEffect, ReactNode, useRef } from 'react';

// interface UserLocation {
//   ip: string;
//   network: string;
//   version: string;
//   city: string;
//   region: string;
//   region_code: string;
//   country: string;
//   country_name: string;
//   country_code: string;
//   country_code_iso3: string;
//   country_capital: string;
//   country_tld: string;
//   continent_code: string;
//   in_eu: boolean;
//   postal: string;
//   latitude: number;
//   longitude: number;
//   timezone: string;
//   utc_offset: string;
//   country_calling_code: string;
//   currency: string;
//   currency_name: string;
//   languages: string;
//   country_area: number;
//   country_population: number;
//   asn: string;
//   org: string;
// }

// interface UserLocationProviderProps {
//   children: ReactNode;
// }

// export const UserLocationContext = createContext<UserLocation | undefined>(undefined);

// export const useUserLocation = () => useContext(UserLocationContext);

// export const UserLocationProvider: React.FC<UserLocationProviderProps> = ({ children }) => {
//   const [userLocation, setUserLocation] = useState<UserLocation | undefined>(undefined);
//   const [error, setError] = useState<string | null>(null);
//   const hasFetched = useRef(false);

//   useEffect(() => {
//     const fetchUserLocation = async () => {
//       if (hasFetched.current) return; // Prevent multiple fetches
      
//       hasFetched.current = true; // Mark as fetched

//       try {
//         const response = await fetch('https://ipapi.co/json/?fields=ip,network,version,city,region,region_code,country,country_name,country_code,country_code_iso3,country_capital,country_tld,continent_code,in_eu,postal,latitude,longitude,timezone,utc_offset,country_calling_code,currency,currency_name,languages,country_area,country_population,asn,org');
        
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
        
//         const data: UserLocation = await response.json();
//         setUserLocation(data);
        
//       } catch (err) {
//         setError('Error fetching IP data');
//         console.error('Error fetching IP data:', err);
//       }
//     };

//     fetchUserLocation();

//     return () => {
//       // Cleanup if needed
//       hasFetched.current = true; // Ensures that cleanup doesn't affect the fetch state
//     };
//   }, []);

//   return (
//     <UserLocationContext.Provider value={userLocation}>
//       {error && <div>{error}</div>}
//       {children}
//     </UserLocationContext.Provider>
//   );
// };



import React, { createContext, useContext, useState, useEffect, ReactNode, useRef } from 'react';

interface UserLocation {
  ip: string;
  network: string;
  version: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_name: string;
  country_code: string;
  country_code_iso3: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  country_area: number;
  country_population: number;
  asn: string;
  org: string;
}

interface UserLocationProviderProps {
  children: ReactNode;
}

export const UserLocationContext = createContext<UserLocation | undefined>(undefined);

export const useUserLocation = () => useContext(UserLocationContext);

export const UserLocationProvider: React.FC<UserLocationProviderProps> = ({ children }) => {
  const [userLocation, setUserLocation] = useState<UserLocation | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchUserLocation = async () => {
      if (hasFetched.current) return; // Prevent multiple fetches
      
      hasFetched.current = true; // Mark as fetched

      // Check session storage first
      const storedLocation = sessionStorage.getItem('userLocation');
      if (storedLocation) {
        setUserLocation(JSON.parse(storedLocation));
        return;
      }

      try {
        const response = await fetch('https://ipapi.co/json/?fields=ip,network,version,city,region,region_code,country,country_name,country_code,country_code_iso3,country_capital,country_tld,continent_code,in_eu,postal,latitude,longitude,timezone,utc_offset,country_calling_code,currency,currency_name,languages,country_area,country_population,asn,org');
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data: UserLocation = await response.json();
        setUserLocation(data);
        
        // Store in session storage
        sessionStorage.setItem('userLocation', JSON.stringify(data));
        
      } catch (err) {
        // setError('Error fetching IP data');
        console.error('Error fetching IP data:', err);
      }
    };

    fetchUserLocation();

    return () => {
      // Cleanup if needed
      hasFetched.current = true; // Ensures that cleanup doesn't affect the fetch state
    };
  }, []);

  return (
    <UserLocationContext.Provider value={userLocation}>
      {error && <div>{error}</div>}
      {children}
    </UserLocationContext.Provider>
  );
};
