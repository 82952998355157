// components/GlobalInvisibleReCAPTCHA.tsx
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface GlobalInvisibleReCAPTCHAProps {
  onChange: (value: string | null) => void;
}

const GlobalInvisibleReCAPTCHA: React.FC<GlobalInvisibleReCAPTCHAProps> = ({ onChange }) => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const handleCaptchaChange = (value: string | null) => {
    onChange(value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Execute the reCAPTCHA when the form is submitted
    recaptchaRef.current?.execute();
  };

  const siteKey = process.env.YOUR_SITE_KEY;

  return (
    <form onSubmit={handleSubmit}>
      {/* Your form fields can go here */}
      {/* <button type="submit">Submit</button> */}
      <ReCAPTCHA
        sitekey={siteKey || ''} // Ensure it defaults to an empty string if undefined
        ref={recaptchaRef}
        size="invisible"
        onChange={handleCaptchaChange}
      />
    </form>
  );
};

export default GlobalInvisibleReCAPTCHA;
